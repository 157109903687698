<ion-content class="newsletter-modal-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ 'NEWSLETTER.TITLE' | translate }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="dismiss()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="modal-container">
    <div class="logo-section" *ngIf="!cordova">
      <img src="../../../assets/logo-dark.svg" alt="Ksuki Logo" />
    </div>
    <div class="text-section">
      <!-- <h2>{{ 'NEWSLETTER.TITLE' | translate }}</h2> -->
      <p class="subtitle">{{ 'NEWSLETTER.SUBTITLE' | translate }}</p>
      <p>{{ 'NEWSLETTER.CONTENT' | translate }}</p>
      <p>{{ 'NEWSLETTER.PROMISE' | translate }}</p>
      <ion-button expand="block" style="max-width: 400px;" (click)="subscribe()" class="subscribe-button">{{ 'NEWSLETTER.BUTTON' | translate }}</ion-button>
      <p class="offer-text">{{ 'NEWSLETTER.OFFER' | translate }}</p>
    </div>
  </div>
</ion-content>