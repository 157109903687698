export const common = {
  apiStatic: 'static',
  apiRouteSerie: 'serie/detail',
  apiRouteSerieCover: 'serie/{0}/cover',
  apiRoutePageThumb: 'chapters/{0}/thumbs/{1}',
  apiRouteChapterThumbs: 'chapter/{0}/thumbs',
  apiRouteSerieOffline: 'serie/offline',
  apiRouteVolume: 'volume/detail',
  apiRouteVolumeCover: 'volume/{0}/cover',
  apiRouteBanner: 'banner',
  apiRouteSerieSearch: 'series/search',
  apiRouteAdvancedSerieSearch: 'serie/advancedSearch',
  apiRouteSerieMostPopular: 'serie/popular',
  apiRouteLastReleases: 'serie/lastReleases',
  apiRouteSerieByAuthor: 'serie/author/{0}',
  apiRouteSerieByEditorial: 'serie/editorial',
  apiRouteSerieByType: 'serie/type/{0}',
  apiRouteRecentlySearchedSeries: 'serie/recentlySearched',
  apiRouteHentaiSeries: 'serie/hentai',
  apiRouteAlltimeSearchedSeries: 'serie/alltimeSearched',
  apiRouteFollowingSeries: 'serie/followed/{0}',
  apiRouteRecomendedSeries: 'serie/recomended/{0}',
  apiRouteNextReleases: 'serie/nextReleases',
  apiRouteLastReads: 'serie/user/{0}/lastReads',
  apiRouteSeriesWithAnnotations: 'serie/user/{0}/annotations',
  apiRouteSerieByGenre: 'serie/genres/{0}',
  apiCanAccessSerie: 'serie/{0}/canAccess',
  apiRouteSerieFormats: 'serie/formats',
  apiRouteSerieByFormat: 'serie/format/{0}',

  apiRouteChapterFiles: 'chapters/{0}/files',
  apiRouteChapter: 'chapter/detail/{0}',
  apiRouteCreatePageLink: 'chapters/{0}/pages/{1}/links',
  apiRouteEditPageLink: 'chapters/{0}/pages/{1}/links/{2}',
  apiRouteRemovePageLink: 'chapters/{0}/pages/{1}/links/{2}',
  apiRouteChapterLangs: 'chapter/{0}/langs',

  apiRouteAdvancedUserSearch: 'user/advancedSearch',
  apiRouteSetFirstTimeLogged: 'userStatus/{0}/firstTimeLogged',
  apiRouteValidateUserAccount: 'user/validation/{0}',
  apiRouteSendAccountValidationEmail: 'auth/mail/accountValidation',
  apiRouteSendEmailValidationEmail: 'auth/mail/emailValidation',
  apiRouteCheckUsername: 'user/check',
  apiRouteChangeUsername: 'users/{0}/username',
  apiRouteChangeLibraryPrivacy: 'userStatus/libraryPrivacy',
  apiRouteChangeAllowDirectMessages: 'userStatus/directMessages',
  apiRouteChangeSeriesNewsFrequency: 'userStatus/seriesNewsFrequency',
  apiRouteChangePushNotif: 'userStatus/pushNotif',
  apiRouteChangeCountry: 'country/change',
  apiRouteUpdateAuthor: 'author/{0}/update',
  apiRouteUpdateUserProfile: 'user/profile',
  apiRouteCreateCustomSection: 'user/customSection',
  apiRouteGetCustomSections: 'user/{0}/customSections',
  apiRouteGetCustomSection: 'user/customSection/{0}',
  apiRouteDeleteCustomSection: 'user/customSection',
  apiRouteDuplicateCustomSection: 'user/customSection/{0}/duplicate',
  apiRouteChangeLanguage: 'userStatus/language',
  apiRoutePublicUser: 'user/{0}/public',
  apiRouteUserAvatar: 'users/{0}/avatar',
  apiRouteUploadAvatar: 'user/avatar',
  apiRouteUserSubscription: 'users/{0}/subscription/{1}',
  apiRouteUserDetail: 'user/{0}/detail',
  apiRouteUserCheckBirth: 'user/checkBirthDate',
  apiRouteUserCheckNewsletter: 'user/checkNewsletter',
  apiRouteUserSubscribeNewsletter: 'user/subscribeNewsletter',

  apiRouteGetCollections: 'collection',
  apiRouteGetCollectionSeries: 'collection/{0}',
  apiRouteDeleteCollection: 'collection/{0}',
  apiRouteDeleteSerieFromCollection: 'collection/{0}/deleteSerie',
  apiRouteUpdateCollection: 'collection/{0}',
  apiRouteAddSerieToCollection: 'collection/{0}/addSerie',
  apiRouteCreateCollection: 'collection',

  apiRouteBlockUser: 'social/user/{0}/block',
  apiRouteUnblockUser: 'social/user/{0}/unblock',

  apiRouteGenres: 'genre/app',
  apiRouteLangs: 'lang/app',
  apiRouteTypes: 'type/app',
  apiRouteAuthors: 'author/app',
  apiRouteAuthor: 'author/app/{0}',
  apiRouteEditorials: 'editorial/app',
  apiRouteEditorial: 'editorial/app',

  apiRouteFollowSerie: 'social/{0}/serie/{1}/follow',
  apiRouteUnfollowSerie: 'social/{0}/serie/{1}/unfollow',
  apiRouteRateSerie: 'serie/{0}/rate',

  apiRouteFollowUser: 'social/user/{0}/follow',
  apiRouteUnfollowUser: 'social/user/{0}/unfollow',
  apiRouteGetFollowUser: 'social/user/{0}/follow',
  apiRouteGetFollowers: 'social/user/{0}/followers',
  apiRouteGetFollowingUsers: 'social/user/{0}/following',
  apiRouteGetRecommendedUsers: 'user/{0}/recommended',
  apiRouteGetTopLikesUsers: 'user/topLikes',

  apiRouteLikeChapter: 'social/chapter/{0}/like',
  apiRouteUnlikeChapter: 'social/chapter/{0}/unlike',
  apiRouteSendCommentChapter: 'comment',
  apiRouteSendCommentReply: 'users/{0}/comments/{1}/comment',
  apiRouteGetComment: 'comment',
  apiRouteGetCommentsChapter: 'comment/chapter',
  apiRouteGetCommentsSerie: 'comment/serie',
  apiRouteGetCommentsByUser: 'social/{0}/comments/user/{1}',
  apiRouteGetCommentReplies: 'comment/replies',
  apiRouteLikeComment: 'comment/{0}/like',
  apiRouteReportComment: 'report',
  apiRouteMarkSpoilerComment: 'comment/{0}/spoiler',
  apiRouteCommentStatus: 'users/{0}/status/comment',
  apiRouteCommentImage: 'comment/{0}/image',
  apiRouteShareSerie: 'serie/{0}/share',

  apiRouteGetNews: 'social/{0}/userNews',

  apiRouteSerieStatus: 'userStatus/{0}/serie',
  apiRouteChapterStatus: 'userStatus/{0}/chapter',
  apiRouteCurrentChapter: 'userStatus/currentChapter',
  apiRouteAnnotations: 'userStatus/serie/{0}/annotations',
  apiRouteCurrentPage: 'userStatus/{0}/currentPage',
  apiRouteCurrentPercentage: 'userStatus/{0}/currentPercentage',
  apiRouteSetPageAsFavorite: 'userStatus/favoritePage',
  apiRouteFavoritePages: 'userStatus/{0}/favoritePages',
  apiRouteFavoritePagesBySerie: 'userStatus/{0}/favoritePages/serie/{1}',
  apiRouteFinishChapter: 'users/{0}/status/chapter/finish',
  apiRouteVolumeStatus: 'userStatus/{0}/volume',
  apiRouteChapterProductStatus: 'userStatus/{0}/chapterProduct',
  apiRouteHideGenres: 'userStatus/genres/hide',
  apiRouteMarkGenresAsFavourite: 'userStatus/genres/favourites',
  apiRouteGetFavouriteGenres: 'user/{0}/genres/favourites',
  apiRouteMarkLangsAsFavourite: 'userStatus/langs/favourites',
  apiRouteGetFavouriteLangs: 'user/{0}/langs/favourites',
  apiRouteContact: 'contact',

  apiRouteGetUserChats: 'chats/user/{0}/all',
  apiRouteGetUserChat: 'chats/user/{0}',
  apiRouteGetChatMessages: 'chats/{0}/messages',
  apiRouteGetChatNonReadedMessages: 'chats/{0}/messages/nonReaded',
  apiRouteCreateUserChat: 'chats',
  apiRouteSendChatMessage: 'chats/{0}/message',
  apiRouteUploadChatMessageAttachedImage: 'chats/{0}/image',
  
  apiRouteRegister: 'auth/register',
  apiRouteChangePsw: 'auth/changePassword',
  apiRouteChangeEmail: 'auth/changeEmail/{0}',
  /*itemsCheckoutApiRoute: 'checkout/items',*/
  apiRouteStripeSubscriptionCheckoutSession: 'payment/stripeSubscription/checkout/create',
  apiRouteStripeIAPCheckoutSession: 'purchase/stripeIAP/checkout/create',
  apiRouteSubscriptionCheckoutSession: 'payment/subscription/checkout/create', // create checkout iOS & Android
  apiRouteIAPCheckoutSession: 'purchase/IAP/checkout/create', // create checkout iOS & Android
  apiRouteSubscriptionCheckout: 'payment/subscription/checkout/confirm', // confirm checkout and create/modify subscription iOS & Android
  apiRouteIAPCheckot: 'payment/IAP/checkout/confirm', // confirm checkout and make transaction
  apiRouteCancelSubscription: 'payment/subscription/cancel',
  apiRouteStripeCancelSubscription: 'payment/stripeSubscription',
  apiRouteGetSubscriptionProducts: 'purchase/subscription/product',
  apiRouteGetUserPurchasedSubscriptions: 'user/{0}/subscriptions',
  apiRouteGetIAPProducts: 'purchase/iapProduct',
  apiRouteGetAvailableChaptersToBuy: 'purchase/availableChapters',

  apiRouteLogin: 'auth/login',
  apiRouteCheckRecoverPasswordKey: 'auth/password/recover/check',
  apiRouteCheckChangeEmailKey: 'auth/email/change/check',
  apiRouteRecoverPassword: 'auth/password/recover',
  apiRouteResetPassword: 'auth/password/reset',
  apiRouteConfirmEmailChanged: 'auth/email/change',
  apiRouteCheckJWT: 'auth/check',
  apiRouteFindSession: 'auth/findSession/{0}',
  apiRouteIsLoggedIn: 'auth/isLoggedIn/{0}',
  apiRouteGetLoggedInSessions: 'auth/sessions',
  apiRouteLogout: 'auth/logout',
  apiRouteLogoutSession: 'auth/logoutSession',
  apiRouteLogoutDummySessions: 'auth/logoutDummySessions',
  apiRouteAddOneSignalIdToSession: 'auth/addSignal',

  apiRouteGetRemoteSetting: 'remoteSettings/{0}',
  apiRouteGetRemoteSettings: 'remoteSettings',

  apiRouteRssFollowing: 'social/rss/following/{0}',
  apiRouteRssReading: 'social/rss/reading/{0}',
  apiRouteRssCustom: 'social/rss/section/{0}',
  apiRouteRssCollection: 'social/rss/collection/{0}',

  apiRouteGetCountries: 'country',
  apiRouteGetCountry: 'country/{0}',



  apiRouteBuyChapter: 'purchase/chapter',

  apiRouteGetFaqs: 'faq/app',
  apiRouteSendDeleteEmail: 'auth/account/delete/email',
  apiRouteConfirmDelete: 'auth/account/delete',
  appleLoginID: 'com.ontinet.comics-login',
  apiRouteCheckVersion: 'app-version/check',
  apiRouteOfflineReadedPages: 'userStatus/offlinePages'
}