import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'
import { PipesModule } from '../lib/pipes/pipes.module'
import { LikeUnlikeComponent } from './like-unlike/like-unlike.component'
import { RatingComponent } from './rating/rating.component'
import { RatingStarsComponent } from './rating/rating-stars/rating-stars.component'
import { RatingStarComponent } from './rating/rating-star/rating-star.component'
import { UserRatingComponent } from './popovers/user-rating/user-rating.component'
import { ShareComponent } from './share/share.component'
import { SerieListComponent } from './serie-list/serie-list.component'
import { CommentComponent } from './comment/comment.component'
import { TranslateModule } from '@ngx-translate/core'
import { SessionDevicesPage } from '../pages/auth/session-devices/session-devices.page'
import { OfflineSeriesComponent } from './offline-series/offline-series.component'
import { CustomSectionOptionsComponent } from './popovers/custom-section-options/custom-section-options.component'
import { OrderSelectComponent } from './order-select/order-select.component'
import { SeriesContainerSlidesComponent } from './series-container-slides/series-container-slides.component'
import { UserListElementComponent } from './user-list-element/user-list-element.component'
import { DirectivesModule } from '../lib/directives/directives.module'
import { SerieCoverComponent } from './serie-cover/serie-cover.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InfoPopoverComponent } from './popovers/info-popover/info-popover.component'
import { ChapterAccessComponent } from './popovers/chapter-access/chapter-access.component'
import { HideGenresComponent } from './hide-genres/hide-genres.component'
import { UsersListComponent } from './users-list/users-list.component'
import { RouterModule } from '@angular/router'
import { ImagePreviewComponent } from './image-preview/image-preview.component'
import { CameraComponent } from './camera/camera.component'
import { SeriesByAuthorComponent } from './series-by-author/series-by-author.component'
import { TranslatorsPopoverInfoComponent } from './translators-popover-info/translators-popover-info.component'
import { UserProfileOptionsComponent } from './popovers/user-profile-options/user-profile-options.component'
import { VisualLinkComponent } from './visual-link/visual-link.component'
import { LinkPopoverComponent } from './popovers/link-popover/link-popover.component'
import { ReaderOptionsPopoverComponent } from './popovers/reader-options-popover/reader-options-popover.component'
import { FavoritePagesComponent } from './favorite-pages/favorite-pages.component'
import { FavoritePagesContainerSlidesComponent } from './favorite-pages-container-slides/favorite-pages-container-slides.component'
import AppHeader from './layout/app-header.component'
import SuggestDownload from './suggest-download/suggest-download.component'
import ConfirmDeleteDialog from './popovers/confirm-delete-dialog/confirm-delete-dialog.component'
import ReaderHeaderComponent from './reader/manga/reader-header/reader-header.component'
import { ReaderSliderComponent } from './reader/manga/reader-slider/reader-slider.component'
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom'
import { ReaderFooterComponent } from './reader/manga/reader-footer/reader-footer.component'
import { ReaderHorizontalSlideComponent } from './reader/manga/reader-slider/reader-horizontal-slide/reader-horizontal-slide.component'
import { ReaderSlideComponent } from './reader/manga/reader-slide/reader-slide.component'
import { LastPageComponent } from './last-page/last-page.component'
import { ReaderVerticalSlideComponent } from './reader/manga/reader-slider/reader-vertical-slide/reader-vertical-slide.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { ReaderPageSelectorComponent } from './reader/manga/reader-page-selector/reader-page-selector.component'
import { SelectorSlideComponent } from './reader/manga/reader-page-selector/selector-slide/selector-slide.component'
import { LoadingLogoComponent } from './loading-logo/loading-logo.component'
import { SerieApiService } from '../lib/services/api/modules/serie.api'
import { UserApiService } from '../lib/services/api/modules/user.api'
import { GenreApiService } from '../lib/services/api/modules/genre.api'
import { AuthorApiService } from '../lib/services/api/modules/author.api'
import { EditorialApiService } from '../lib/services/api/modules/editorial.api'
import { TypeApiService } from '../lib/services/api/modules/type.api'
import { LangApiService } from '../lib/services/api/modules/lang.api'
import { FaqApiService } from '../lib/services/api/modules/faq.api'
import { CommentApiService } from '../lib/services/api/modules/comment.api'
import { CollectionApiService } from '../lib/services/api/modules/collection.api'
import { SocialApiService } from '../lib/services/api/modules/social.api'
import { LoadingSeriesSkeleton } from './loading-series-skeleton/loading-series-skeleton.component'
import { ImageCoverModalComponent } from '../pages/series/serie-detail/image-cover-modal/image-cover-modal.component'
import { AnnotationsPopoverComponent } from './popovers/annotations-popover/annotations-popover.component'
import { CollectionsPopoverComponent } from './popovers/collections-popover/collections-popover.component'
import { VolumeNumbersPopoverComponent } from './popovers/volume-numbers-popover/volume-numbers-popover.component'
import { FollowUnfollowComponent } from './follow-unfollow/follow-unfollow.component'
import { VisualLinkPopoverComponent } from './popovers/visual-link-popover/visual-link-popover.component'
import { PurchaseSuccessComponent } from './purchase-success/purchase-success.component'
import { BuyVolumeModule } from './buy-volume/buy-volume.module'
import { CustomSectionsComponent } from './custom-sections/custom-sections.component'
import { EpubViewerComponent } from './reader/epub/epub-viewer/epub-viewer.component'
import EpubOptionsPopoverComponent from './popovers/epub-options-popover/epub-options-popover.component'
import { EpubFooterComponent } from './reader/epub/epub-footer/epub-footer.component'
import { SelectBirthDateComponent } from './popovers/select-birthdate/select-birthdate.component'
import { NewsletterComponent } from './newsletter/newsletter.component'

@NgModule({
  declarations: [
    LikeUnlikeComponent,
    RatingComponent,
    RatingStarsComponent,
    RatingStarComponent,
    UserRatingComponent,
    ShareComponent,
    SerieListComponent,
    CommentComponent,
    SessionDevicesPage,
    OfflineSeriesComponent,
    CustomSectionOptionsComponent,
    OrderSelectComponent,
    SeriesContainerSlidesComponent,
    UserListElementComponent,
    SerieCoverComponent,
    InfoPopoverComponent,
    ChapterAccessComponent,
    HideGenresComponent,
    UsersListComponent,
    ImagePreviewComponent,
    CameraComponent,
    SeriesByAuthorComponent,
    TranslatorsPopoverInfoComponent,
    UserProfileOptionsComponent,
    VisualLinkComponent,
    LinkPopoverComponent,
    ReaderOptionsPopoverComponent,
    FavoritePagesComponent,
    FavoritePagesContainerSlidesComponent,
    AppHeader,
    SuggestDownload,
    ConfirmDeleteDialog,
    ReaderHeaderComponent,
    ReaderSliderComponent,
    ReaderFooterComponent,
    ReaderHorizontalSlideComponent,
    ReaderSlideComponent,
    LastPageComponent,
    ReaderVerticalSlideComponent,
    ReaderPageSelectorComponent,
    SelectorSlideComponent,
    LoadingLogoComponent,
    LoadingSeriesSkeleton,
    AnnotationsPopoverComponent,
    CollectionsPopoverComponent,
    VolumeNumbersPopoverComponent,
    FollowUnfollowComponent,
    ImageCoverModalComponent,
    VisualLinkPopoverComponent,
    PurchaseSuccessComponent,
    CustomSectionsComponent,
    EpubViewerComponent,
    EpubOptionsPopoverComponent,
    EpubFooterComponent,
    SelectBirthDateComponent,
    NewsletterComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    PinchZoomModule,
    BuyVolumeModule
  ],
  exports: [
    LikeUnlikeComponent,
    RatingComponent,
    RatingStarsComponent,
    RatingStarComponent,
    UserRatingComponent,
    ShareComponent,
    SerieListComponent,
    CommentComponent,
    SessionDevicesPage,
    OfflineSeriesComponent,
    CustomSectionOptionsComponent,
    OrderSelectComponent,
    SeriesContainerSlidesComponent,
    UserListElementComponent,
    SerieCoverComponent,
    InfoPopoverComponent,
    ChapterAccessComponent,
    HideGenresComponent,
    UsersListComponent,
    ImagePreviewComponent,
    CameraComponent,
    SeriesByAuthorComponent,
    TranslatorsPopoverInfoComponent,
    UserProfileOptionsComponent,
    VisualLinkComponent,
    LinkPopoverComponent,
    ReaderOptionsPopoverComponent,
    FavoritePagesComponent,
    FavoritePagesContainerSlidesComponent,
    AppHeader,
    SuggestDownload,
    ConfirmDeleteDialog,
    ReaderHeaderComponent,
    ReaderSliderComponent,
    ReaderFooterComponent,
    ReaderPageSelectorComponent,
    LoadingLogoComponent,
    LoadingSeriesSkeleton,
    AnnotationsPopoverComponent,
    CollectionsPopoverComponent,
    VolumeNumbersPopoverComponent,
    FollowUnfollowComponent,
    ImageCoverModalComponent,
    VisualLinkPopoverComponent,
    PurchaseSuccessComponent,
    CustomSectionsComponent,
    EpubViewerComponent,
    EpubOptionsPopoverComponent,
    EpubFooterComponent,
    SelectBirthDateComponent,
    NewsletterComponent,
  ],
  providers: [
    UserRatingComponent, //es un provider porque lo recibimos en el constructor en una ocasión
    SerieApiService,
    UserApiService,
    GenreApiService,
    AuthorApiService,
    EditorialApiService,
    TypeApiService,
    LangApiService,
    FaqApiService,
    CommentApiService,
    CollectionApiService,
    SocialApiService,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsModule {}
