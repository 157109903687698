import { Injectable } from "@angular/core"
import { UserApiService } from "../services/api/modules/user.api"
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router"

@Injectable({
  providedIn: "root",
})
export class ExplicitGuard {
  constructor(
    private userApiService: UserApiService,
    private router: Router,
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { serieId } = route.queryParams

    const { valid } = await this.userApiService.checkBirthDate(serieId)

    if (valid) return true
    else {
      this.router.navigate(["/"])
      return false
    }
  }
}