import { ElementRef, Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { LocalizationService } from './localization.service'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { App } from '@capacitor/app'
import { StorageService } from 'src/app/lib/services/storage.service'
import { PRODUCT_PLATFORM } from '../interfaces/product/product.model'
import { AUTHOR_ROLE } from '../interfaces/author/author.model'
import { GlobalService } from './global.service'
import { User } from '../interfaces/user/user.model'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx'

export const SK = {
  TOKEN: 'token',
  DARK_MODE: 'darkMode',
  USER_ID: 'userId',
  APP_LANG: 'appLang',
  IMAGE_QUALITY: 'imageQuality',
  SESSION_ID: 'sessionId',
  READING_MODE: 'readingMode',
  OFFLINE_SERIES: 'offlineSeries',
  REMOTE_SETTINGS: 'remoteSettings',
  PAGE_ORDER: 'pageOrder',
  TUTORIAL: 'tutorial',
  SERIES_AUTOPURCHASE: 'seriesAutopurchase',
  SERIES_READING_MODE: 'seriesReadingMode',
  SERIES_SHOW_LINKS: 'seriesShowLinks',
  CHAPTERS_THUMBS: 'chaptersThumbs',
  ANNOTATIONS_REMINDED: 'annotationsReminded',
  ONE_SIGNAL_ID: 'oneSignalId',
  READER_BRIGHTNESS: 'readerBrightness',
  SUGGEST_TIME: 'suggestTime',
  READER_LANG: 'readerLang',
  PUSH_REQ_TIME: 'pushReqTime',
  PUSH_REQ_COUNT: 'pushReqCount',
  EPUB_FONT_SIZE: 'epubFontSize',
  EPUB_LANG: 'epubLang',
  NEWSLETTER_REQ_TIME: 'newsletterReqTime',
  NEWSLETTER_REQ_COUNT: 'newsletterReqCount',
} as const
type SK = typeof SK[keyof typeof SK]

export const READ_MODE = {
  RIGHT_TO_LEFT: 'rtl',
  LEFT_TO_RIGHT: 'ltr',
  VERTICAL_FLUID: 'vf',
  AUTOMATIC: 'auto',
}

export interface Year {
  id: number
  name: string
}

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    public platform: Platform, 
    private localizationService: LocalizationService,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private storage: StorageService,
    ) {
    this.initialize()
  }

  async initialize() {
    moment.locale(this.localizationService.getDeviceLangCode())
  }

  public async getStoredReadingMode(serieID: number) {
    const storedReadingMode = await this.storage.get(SK.SERIES_READING_MODE)
    
    if (storedReadingMode) {
      const serie = storedReadingMode.find(x => x.serieId == serieID)
      if (serie?.mode) return serie.mode
    }

    return await this.storage.get(SK.READING_MODE)
  }

  public async getStoredReaderLang(): Promise<number> {
    const appLang = parseInt(await this.storage.get(SK.APP_LANG))
    const readerLang = parseInt(await this.storage.get(SK.READER_LANG))

    if (readerLang) return readerLang
    return appLang
  }

  public getProductPlatform(): PRODUCT_PLATFORM {
    if (this.platform.is('android') && !this.platform.is('mobileweb')) return PRODUCT_PLATFORM.ANDROID
    if (this.platform.is('ios') && !this.platform.is('mobileweb')) return PRODUCT_PLATFORM.IOS
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) return PRODUCT_PLATFORM.STRIPE
  }

  public getPlatform(): string {
    if (this.platform.is('android') && !this.platform.is('mobileweb')) return 'android'
    if (this.platform.is('ios') && !this.platform.is('mobileweb')) return 'ios'
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) return 'web'
  }

  b64toBlob(b64Data, sliceSize = 2048) {
    const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''))
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays)
    return blob
  }

  public isLargeDevice() {
    if (
      this.platform.is('tablet') ||
      this.platform.is('desktop') ||
      this.platform.is('pwa') ||
      this.platform.is('ipad')
    ) {
      return true
    } else {
      return false
    }
  }

  shouldModalsAreFullScreen() {
    if (this.platform.is('tablet') || this.platform.is('desktop') || this.platform.is('ipad')) {
      return true
    } else {
      return false
    }
  }

  isMobileDevice() {
    return this.platform.platforms().includes('ios') || this.platform.platforms().includes('android')
  }

  isMobileApp() {
    return (this.platform.platforms().includes('ios') || this.platform.platforms().includes('android')) && !this.platform.platforms().includes('mobileweb')
  }

  public isDesktop() {
    return (
      this.platform.platforms().includes('desktop') &&
      !this.platform.platforms().includes('android') &&
      !this.platform.platforms().includes('ios')
    )
  }

  public isXXLScreen() {
    return this.platform.width() >= 1024
  }

  public slideSizeByScreen() {
    let platformWidth = this.platform.width()
    const CONTAINER_MAX_WIDTH = 1024
    if (platformWidth > CONTAINER_MAX_WIDTH) {
      platformWidth = CONTAINER_MAX_WIDTH
    }
    const decimalWidth = platformWidth / 100
    const coverDiv = 1.65

    return decimalWidth / coverDiv

  }

  public coverSizeByScreen() {
    if (this.platform.width() < 768) {
      return 3.5
    } else if (this.platform.width() >= 768 && this.platform.width() < 1024) {
      return 3
    } else {
      return 2
    }
  }

  public isAndroid() {
    return this.platform.platforms().includes('android')
  }

  public isiOS() {
    return this.platform.platforms().includes('ios')
  }

  public isCapacitor() {
    return (
      (this.platform.platforms().includes('android') || this.platform.platforms().includes('ios')) &&
      this.platform.platforms().includes('capacitor')
    )
  }

  public isMobileWeb() {
    return this.platform.platforms().includes('mobileweb')
  }

  public isWeb () {
    return (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb'))
  } // end_isWeb

  public isIPad(): boolean {
    return this.platform.platforms().includes('ipad')
  }

  public calculateRating(ratingCount: number, ratingSum: number) {
    let result = ratingCount > 0 ? Math.round((ratingSum / ratingCount) * 10) / 10 : 0
    return result == 0 ? '-' : result
  }

  public formatLargeNumber(numberToFormat: number): string {
    if (numberToFormat === 0) return '0'
    if (!numberToFormat) return ''
    if (numberToFormat >= 1000 && numberToFormat < 1000000) {
      return (numberToFormat / 1000).toPrecision(3) + 'k'
    } else if (numberToFormat >= 1000000) {
      return (numberToFormat / 1000000).toPrecision(3) + 'M'
    } else {
      return numberToFormat.toString()
    }
  }

  public formatDate(date) {
    return moment(date).format('DD/MM/YYYY HH:MM:ss')
  }

  public generateIntCurDate(): number {
    return parseInt(moment().format('YYYYMNNMDD'))
  } // end_generateCurDate

  public getUnique(length: number) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  public compressImage(src: string, isAvatar: boolean = false): Promise<Blob> {
    return new Promise((resolve) => {
      const width = isAvatar ? 400 : 800
      const height = isAvatar ? 400 : 800
      const img = new Image()
      img.src = src
      img.onload = () => {
        const scaleFactorWidth = width / img.width
        const scaleFactorHeight = height / img.height
        const elem = document.createElement('canvas')
        elem.width = img.width > img.height ? width : img.width * scaleFactorHeight
        elem.height = img.height > img.width ? height : img.height * scaleFactorWidth

        const ctx = elem.getContext('2d')
        ctx.drawImage(img, 0, 0, elem.width, elem.height)
        ctx.canvas.toBlob(
          (blob) => {
            /*
          const file = new File([blob], 'image', {
            type: 'image/jpeg',
            lastModified: Date.now()
          })
          return file;
          */
            resolve(blob)
          },
          'image/jpeg',
          0.5,
        )
      }
    })
  }

  async getRoleTranslation(role: string) {
    switch (role) {
      case AUTHOR_ROLE.WRITER:
        return await this.localizationService.i18n('AUTHOR_ROLE.WRITER')
      case AUTHOR_ROLE.ARTIST:
        return await this.localizationService.i18n('AUTHOR_ROLE.ARTIST')
      case AUTHOR_ROLE.COLORIZER:
        return await this.localizationService.i18n('AUTHOR_ROLE.COLORIZER')
      case AUTHOR_ROLE.INKER:
        return await this.localizationService.i18n('AUTHOR_ROLE.INKER')
      case AUTHOR_ROLE.TRANSLATOR:
        return await this.localizationService.i18n('AUTHOR_ROLE.TRANSLATOR')
      case AUTHOR_ROLE.ASSISTANT:
        return await this.localizationService.i18n('AUTHOR_ROLE.ASSISTANT')
      case AUTHOR_ROLE.ADVISER:
        return await this.localizationService.i18n('AUTHOR_ROLE.ADVISER')
      case AUTHOR_ROLE.ORIGINAL_IDEA:
        return await this.localizationService.i18n('AUTHOR_ROLE.ORIGINAL_IDEA')
    }
  }

  public redirectToStores(): void {
    if (this.isAndroid()) window.location.href = 'https://play.google.com/store/apps/details?id=com.ontinet.volumes&hl=es'
    else window.location.href = 'https://apps.apple.com/app/ksuki/id1546598377'
  } // end_redirectToStores

  handleBackButton() {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      const url = this.router.url
      const page = this.router.url.split("/", 3)
      if (!this.router.navigated || page[2] === 'tabs') {
        if (url === '/main/tabs/home') App.exitApp()
        else this.router.navigate(['/main/tabs/home'])
      } // end_if
    })
  } // end_handleBackButton

  loadSerieThumbs() {}

  public async getStoredBrigthness() {
    return parseInt(await this.storage.get(SK.READER_BRIGHTNESS)) || 75
  } // end_getStoredBrightness

  public getPlaceholder(isDark: boolean, level: number = 0): string {
    const backPath = level === 0 ? './../../..' : './../../../..'
    return `${backPath}/assets/img-placeholders/placeholder_${isDark ? 'dark' : 'light'}.png`
  }

  public getLastYears(amount: number) {
    const currentDate = new Date()
    const currentYear: number = currentDate.getFullYear()
    let lastYears: any[] = []

    for (let i = 0; i < amount; i++) {
      lastYears.push({
        id: currentYear - i,
        name: currentYear - i,
      })
    }

    return lastYears
  }

  public isUserAdult(user: User) {
    if (!user?.dob) {
      return false
    } else {
      const dob = moment(user.dob).format('YYYY-MM-DD')
      const age = moment().diff(dob, 'years')

      if (age >= 18) {
        return true
      }
    }
  }

  public async weekTrigger(reqDate,reqCount): Promise<boolean> {
    if (!reqDate) {
      return true
    } else {
      const now = new Date()
      const diff = now.getTime() - new Date(reqDate).getTime()
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
  
      if (diffDays >= 10 && reqCount < 3) {
        return true
      }
      
      return false
    }
  }

  public isTablet() {
    // const screenWidth = window.innerWidth;
    // const MAX_PHONE_SCREEN_WIDTH = 420
    // if (screenWidth > MAX_PHONE_SCREEN_WIDTH) return true
    if (this.platform.is('tablet') || this.platform.is('ipad')) return true
    return false;
  }

  isLandscape(): boolean {
    return this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
      this.screenOrientation.type === this.screenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY;
  }
}
