import { Component, OnInit } from '@angular/core'
import { ModalController, Platform } from '@ionic/angular';
import { UserApiService } from 'src/app/lib/services/api/modules/user.api';
import { UtilService } from 'src/app/lib/services/util.service';


@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit{

  public cordova: boolean = false;
  constructor(
    private modalController: ModalController,
    private userApiService: UserApiService,
    private utilsService: UtilService
  ) {}
  
  ngOnInit(): void {
    if (this.utilsService.isCapacitor()) this.cordova = true;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async subscribe(){
    await this.userApiService.subscribeNewsletter(true);
    this.dismiss();
  }
}
