import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './lib/guards/auth.guard'
import { IsMobileGuard } from './lib/guards/is-mobile.guard'
import { ChapterAccessGuard } from './lib/guards/chapter-access.guard'
import { SerieDetailGuard } from './lib/guards/serie-detail.guard'
import { ExplicitGuard } from './lib/guards/explicit.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main/tabs/home',
  },
  {
    path: 'main',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    /*canLoad: [AuthGuard],*/
    //canActivate: [AuthGuard]
  },
  {
    path: 'serie-detail/:serieId',
    loadChildren: () => import('./pages/series/serie-detail/serie-detail.module').then((m) => m.SerieDetailPageModule),
    canActivate: [SerieDetailGuard]
  },
  {
    path: 'reader/:chapterId',
    loadChildren: () => import('./pages/player/reader-v2/reader-v2.module').then((m) => m.ReaderV2PageModule),
    canActivate: [AuthGuard, ExplicitGuard, ChapterAccessGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/auth/forgot/forgot.module').then((m) => m.ForgotPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/social/contact/contact.module').then((m) => m.ContactPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
    //canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/auth/account/account.module').then((m) => m.AccountPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },

  {
    path: 'series-by-editorial/:editorialId',
    loadChildren: () =>
      import('./pages/series/series-by-editorial/series-by-editorial.module').then((m) => m.SeriesByEditorialPageModule),
  },
  {
    path: 'series-by-type/:typeId',
    loadChildren: () => import('./pages/series/series-by-type/series-by-type.module').then((m) => m.SeriesByTypePageModule),
  },
  {
    path: 'series-by-author/:authorId',
    loadChildren: () =>
      import('./pages/series/series-by-author/series-by-author.module').then((m) => m.SeriesByAuthorPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/auth/change-password/change-password.module').then((m) => m.ChangePasswordPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/auth/change-email/change-email.module').then((m) => m.ChangeEmailPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'comments/chapter/:chapterId',
    loadChildren: () => import('./pages/social/comments/comments.module').then((m) => m.CommentsPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'comments/comment/:commentId',
    loadChildren: () => import('./pages/social/comments/comments.module').then((m) => m.CommentsPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'comments/serie/:serieId',
    loadChildren: () => import('./pages/social/comments/comments.module').then((m) => m.CommentsPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'download-manager',
    loadChildren: () =>
      import('./pages/download-manager/download-manager.module').then((m) => m.DownloadManagerPageModule),
    canActivate: [AuthGuard, IsMobileGuard],
  },
  {
    path: 'offline-content',
    loadChildren: () =>
      import('./pages/offline-content/offline-content.module').then((m) => m.OfflineContentPageModule),
    canActivate: [AuthGuard, IsMobileGuard],
  },
  {
    path: 'offline-content-detail/:serieId',
    loadChildren: () =>
      import('./pages/offline-content-detail/offline-content-detail.module').then(
        (m) => m.OfflineContentDetailPageModule,
      ),
    canActivate: [AuthGuard, IsMobileGuard],
  },
  {
    path: 'offline-reader/:chapterId',
    loadChildren: () => import('./pages/player/offline-reader-v2/offline-reader-v2.module').then((m) => m.OfflineReaderV2PageModule),
    canActivate: [AuthGuard, IsMobileGuard],
  },
  {
    path: 'list', //*:friendlyUrl',*/
    loadChildren: () =>
      import('./pages/series/series-by-generic/series-by-generic.module').then((m) => m.SeriesByGenericPageModule),
  },
  {
    path: 'pagesList',
    loadChildren: () => import('./pages/pages-by-serie/pages-by-serie.module').then((m) => m.PagesBySeriePageModule),
  },
  {
    path: 'users-list',
    loadChildren: () =>
      import('./pages/users/users-by-generic/users-by-generic.module').then((m) => m.UsersByGenericPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-profile/:userId',
    loadChildren: () => import('./pages/users/user-profile/user-profile.module').then((m) => m.UserProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-list/:userId',
    loadChildren: () => import('./pages/users/user-list/user-list.module').then((m) => m.UserListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rss',
    loadChildren: () => import('./pages/social/rss/rss.module').then((m) => m.RssPageModule),
  },

  {
    path: 'collection-manager/:collectionId',
    loadChildren: () =>
      import('./pages/collection-manager/collection-manager.module').then((m) => m.CollectionManagerPageModule),
  },
  {
    path: 'series-by-genre/:genreId',
    loadChildren: () => import('./pages/series/series-by-genre/series-by-genre.module').then((m) => m.SeriesByGenrePageModule),
  },
  {
    path: 'session-devices',
    loadChildren: () =>
      import('./pages/auth/session-devices/session-devices.module').then((m) => m.SessionDevicesPageModule),
  },
  {
    path: 'account-validation/:userId',
    loadChildren: () =>
      import('./pages/auth/account-validation/account-validation.module').then((m) => m.AccountValidationModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: 'email-changed',
    loadChildren: () => import('./pages/auth/email-changed/email-changed.module').then((m) => m.EmailChangedPageModule),
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop.module').then((m) => m.ShopPageModule),
    canActivate: [AuthGuard],
    data: { redirect: true }
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then((m) => m.MaintenancePageModule),
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then((m) => m.UpdatePageModule),
    canActivate: [IsMobileGuard],
  },
  {
    path: 'series-by-type',
    loadChildren: () => import('./pages/series/series-by-type/series-by-type.module').then((m) => m.SeriesByTypePageModule),
  },
  {
    path: 'video-player/:chapterId',
    loadChildren: () => import('./pages/player/video-player/video-player.module').then((m) => m.VideoPlayerPageModule),
    canActivate: [AuthGuard, ChapterAccessGuard],
  },
  {
    path: 'book-reader/:chapterId',
    loadChildren: () => import('./pages/player/book-reader/book-reader.module').then((m) => m.BookReaderModule),
    canActivate: [AuthGuard, ChapterAccessGuard],
  },
  {
    path: 'pages-by-serie',
    loadChildren: () => import('./pages/pages-by-serie/pages-by-serie.module').then((m) => m.PagesBySeriePageModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'main/tabs/home',
  },
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
